<template>
  <div>
    <div class="app-wrapper">
      <div class="container py-5">
        <div class="col-lg-10 mx-auto p-0 app-wrapper__content">
          <form @submit.prevent="search">
            <div class="input-group mb-3 ana-input__group">
              <input
                type="text"
                name="prefix"
                class="form-control ana-input"
                v-model.trim="$v.searchQuery.$model"
                placeholder="Try searching"
                required
              />
              <div class="input-group-append ml-0" v-if="searchQuery">
                <button
                  type="button"
                  class="ana-btn search-clear search-btn"
                  @click="searchQuery = ''"
                >
                  <i class="ti-close"></i>
                </button>
              </div>
              <div class="input-group-append">
                <button type="submit" class="ana-btn ana-btn__fill search-btn">
                  <i class="ti-search"></i>
                </button>
              </div>
            </div>
            <InlineAlert
              alertMessage="Search should be at list 3 characters"
              alertType="error"
              v-if="searchQuery && $v.searchQuery.$invalid"
            />
          </form>
          <div class="d-flex justify-content-center py-2" v-if="isLoading">
            <LinearLoader />
          </div>
          <div
            class="empty-links text-center py-5 col-lg-4 mx-auto"
            v-if="!links.length > 0"
          >
            <h5 class="ana_h5 ana_text-grey py-4">{{ infoMessage }}</h5>
            <div
              class="d-flex align-items-center justify-content-center text-center"
            >
              <router-link
                to="/dash/YourLinks/Create"
                class="ana-btn ana-btn-dash ana-btn__fill"
                >Create New
              </router-link>
            </div>
            <img
              src="@/assets/img/app/find.svg"
              class="d-block mx-auto my-4"
              alt=""
            />
          </div>
          <div class="my-4 mb-5" v-if="links.length > 0">
            <div
              class="py-4 d-flex align-items-center justify-content-between w-100"
              v-if="searchTerm"
            >
              <h3 class="ana_h3 ana_text-dark mb-0">
                Search results for: "{{ searchTerm }}"
              </h3>
            </div>
            <div
              class="py-4 d-flex align-items-center justify-content-between w-100"
            >
              <p class="ana_paragraph ana-text--bold ana_text-dark mb-0">
                Results ({{ totalResults }})
              </p>
            </div>
            <div class="link-area row p-2">
              <div
                v-for="link in links"
                class="w-100 p-2 col-lg-4"
                :key="link._id"
              >
                <LinkCard :link="link" />
              </div>
            </div>
            <Pagination
              :pagination="pagination"
              :loadingNextPage="loadingNextPage"
              @nextPage="nextPage"
              @previousPage="previousPage"
              v-if="links.length > 0"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import LinkCard from "@/components/dash/LinkCard.vue";
import { required, minLength, between, email } from "vuelidate/lib/validators";
import Pagination from "@/components/dash/Pagination";
export default {
  components: {
    LinkCard,
    Pagination,
  },
  metaInfo: {
    title: "Your Links - Ana app",
  },
  name: "Links",
  data() {
    return {
      links: [],
      isLoading: false,
      loadingNextPage: false,
      infoMessage:
        "Search for a link you have created or proceed to create a new link",
      searchQuery: "",
      searchTerm: null,
      totalResults: null,
      pagination: {
        page: 1,
        totalPages: 1,
        perPage: 12,
      },
    };
  },
  validations: {
    searchQuery: {
      required,
      minLength: minLength(3),
    },
  },
  methods: {
    async search() {
      console.log(this.searchQuery);
      const searchQuery = this.searchQuery ? this.searchQuery : this.searchTerm;
      this.isLoading = true;
      try {
        const res = await axios.get(`search`, {
          params: {
            q: searchQuery,
            page: this.pagination.page,
            perPage: this.pagination.perPage,
          },
        });

        if (res.data.result.length === 0) {
          this.infoMessage = `No results matching your search for "${searchQuery}"`;
        } else {
          this.links = res.data.result;
          this.searchTerm = res.data.searchTerm;
          this.totalResults = res.data.totalResults;
          this.pagination = res.data.pagination;
        }
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.$toast(`An error has occurred. Please try again`, {
          timeout: 6000,
          type: "error",
        });
        this.isLoading = false;
      }
    },
    async nextPage() {
      if (
        this.pagination.page < this.pagination.totalPages &&
        !this.loadingNextPage
      ) {
        this.loadingNextPage = true;
        try {
          this.pagination.page++;
          await this.search();
          this.scrollToTop();
        } catch (error) {
          console.log(error);
          this.$toast.error(`An error has occurred. Please try again`);
        } finally {
          this.loadingNextPage = false;
        }
      }
    },
    previousPage() {
      if (
        this.pagination.page > 1 &&
        !this.isLoading &&
        !this.loadingNextPage
      ) {
        this.loadingNextPage = true;
        try {
          this.pagination.page--;
          this.search();
          this.scrollToTop();
        } catch (error) {
          console.log(error);
          this.$toast.error(`An error has occurred. Please try again`);
        } finally {
          this.loadingNextPage = false;
        }
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
